<template>
	<v-card
		elevation="24">

		<div class="d-flex">
			<v-list-item-avatar
				class="ma-3"
				size="125"
				rounded>
				<v-img
					:src="cartProduct.image">
				</v-img>
			</v-list-item-avatar>

			<div class="d-flex justify-space-between flex-grow-1">
				<div class="d-flex flex-column flex-sm-row justify-space-between flex-grow-1">
					<div>
						<v-card-title class="pb-1">
							{{ cartProduct.name }}
						</v-card-title>

						<v-card-text>
							<small class="grey--text">{{ author.fullname }}</small>
						</v-card-text>
					</div>

					<div
						v-if="cartProduct.is_quantifiable"
						class="d-flex align-center px-4">
						<v-btn
							color="grey lighten-3"
							depressed
							x-small
							fab
							@click="cartProduct.decrement()">
							<v-icon>mdi-minus</v-icon>
						</v-btn>
						<p class="title text-h6 font-weight-black mb-0 mx-2">
							{{ cartProduct.quantity }}
						</p>
						<v-btn
							color="grey lighten-3"
							depressed
							x-small
							fab
							@click="cartProduct.increment()">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</div>

					<v-card-title class="text-h4 font-weight-black text-no-wrap">
						{{ cartProduct.total_amount }}₴
					</v-card-title>
				</div>

				<div class="d-flex align-center px-4">
					<v-btn
						color="error lighten-1"
						depressed
						icon
						@click="cartProduct.$delete()">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import User from '../../models/User'

export default {
	props: {
		cartProduct: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		course() {
			return this.cartProduct.courses[0] || {}
		},
		author() {
			return new User(this.course.author || {})
		},
	},
}
</script>
