<template>
	<v-container>
		<section class="mb-10">
			<h1 class="mb-10">Кошик</h1>

			<v-row class="mb-10">
				<v-col
					v-for="cartProduct in items"
					:key="cartProduct.id"
					cols="12">
					<CartProductCard
						:cart-product="cartProduct">
					</CartProductCard>
				</v-col>
			</v-row>

			<div class="d-flex justify-space-between align-end">
				<div class="title size-h5">
					Загалом
				</div>

				<div class="text-h3 font-weight-black font-italic success--text text-no-wrap">
					{{ totalAmount }}₴
				</div>
			</div>
		</section>

		<section class="mb-10">
			<v-btn
				:loading="loading"
				color="btnBg"
				class="btnTxt--text"
				x-large
				block
				dark
				@click="checkAuth(createOrder)">
				Сформувати замовлення
			</v-btn>
		</section>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ordersApi from '../api/ordersApi'

import Product from '../models/Product'

import CartProductCard from '../components/Cart/CartProductCard'

export default {
	components: {
		CartProductCard,
	},
	props: {
		addProducts: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		...mapGetters('auth', [
			'isLoggedIn',
		]),
		...mapGetters('cart', [
			'items',
			'totalAmount',
		]),
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('cart', [
			'addToCart',
			'clearCart',
		]),

		async checkAuth(callback) {
			if (this.isLoggedIn) {
				callback()
			} else {
				await this.$swal('confirm', {
					title: 'Необхідно зареєструватись, щоб продовжити',
					content: 'Ми перенаправимо тебе на сторінку реєстрації. Не переймайся, ми збережемо твоє замовлення і ти зможеш оплатити його одразу після реєстрції',
					icon: '😅',
					buttonCancel: {
						visible: false,
					},
					buttonOk: {
						text: 'Зареєструватись',
					},
				})

				this.$router.push({ name: 'registration' })
			}
		},

		async createOrder() {
			try {
				this.loading = true

				const order_products = this.items.map(({
					id: product_id,
					quantity,
				}) => ({
					product_id,
					quantity,
				}))

				const { data: { id } } = await ordersApi.create({
					order_products,
				})
				
				await this.$router.replace({
					name: 'order',
					params: {
						orderid: id,
					},
				})

				await this.clearCart()

				this.notify({
					text: 'Юху! Замовлення успішно сформоване 🎉',
					color: 'success',
				}, { root: true })

			} catch (error) {
				this.notify({
					text: 'Не вдалося оформити замовлення',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		async addProductsToCart(productsIds) {
			const products = await this.loadProducts({ id: productsIds })
			products.forEach(product => this.addToCart(product))
		},

		async loadProducts(filter) {
			try {
				const products = await Product.getAll({
					filter,
				})
				return products
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити продукти',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	mounted() {
		this.addProductsToCart(this.addProducts)
	},
}
</script>
